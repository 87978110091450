<template>
    <PaymentPage />
</template>

<script>

import PaymentPage from '../components/PaymentPage.vue'

export default {
    data: () => ({
    }),
    components: {
        PaymentPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

